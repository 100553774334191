<template>
  <div class="hello">
      <div id='container'>
        <div  id='DmanTip' class="position-item"  >
          <div id='date'>
            Fecha: 26.03.2021 09:58:31 UTC
          </div>
          <div id='tip'>
           INJ - resillient. Bullish. Good.
          </div>
      </div>
        <div  id='DmanTip' class="position-item"  >
          <div id='date'>
            Fecha:  12.03.2021 17:57:04 UTC
          </div>
          <div id='tip'>
            BAKE, INJ, BZRX, XVS - I'd enter with ORDINARY call allocation only now at this area.
          </div>
      </div>
        <div  id='DmanTip' class="position-item"  >
          <div id='date'>
            Fecha:  21.02.2021 20:32:30 UTC
          </div>
          <div id='tip'>
            INJ I wouldn't touch - at all. it consolidated for so long and it's a coin that pumps at weird times
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'INJ',
  data () {
    return {
    }
  },
  methods: {
  },
}

</script>
